/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  fetchIdoTotalRaised: false,
  fetchUserInfo: false,
}

export const ifosSlice = createSlice({
  name: 'ifos',
  initialState,
  reducers: {
    setFetching(state) {
      state.fetchIdoTotalRaised = !state.fetchIdoTotalRaised
    },
    setFetchUserInfo(state) {
      state.fetchUserInfo = !state.fetchUserInfo
    },
  },
})

// Actions
export const { setFetching,setFetchUserInfo } = ifosSlice.actions

export default ifosSlice.reducer
