import BigNumber from 'bignumber.js'
import SHA256 from 'crypto-js/sha256'
import getUnixTime from 'date-fns/getUnixTime'

export { default as formatAddress } from './formatAddress'

export const bnToDec = (bn: BigNumber, decimals = 18): number => {
  return bn.dividedBy(new BigNumber(10).pow(decimals)).toNumber()
}

export const formatTotalCoin = (num) => {
  let dollarUSLocale = Intl.NumberFormat('en-US')
  const roundDownNum = roundDownSignificantDigits(+num, 4)
  return dollarUSLocale.format(roundDownNum)
}

export function roundDownSignificantDigits(number, decimals) {
  let significantDigits = parseInt(number.toExponential().split('e-')[1]) || 0
  let decimalsUpdated = (decimals || 0) + significantDigits - 1
  decimals = Math.min(decimalsUpdated, number.toString().length)

  return Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals)
}

export const hiddenAddress = (account) =>
  account
    ? `${account[0]}${account[1]}${account[2]}...${account[account.length - 5]}${account[account.length - 4]}${
        account[account.length - 3]
      }${account[account.length - 2]}${account[account.length - 1]}`
    : ''

export const getTime = (now) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return `${monthNames[now.getMonth()]} ${now.getDate()}, ${now.getFullYear()}, ${now.getHours()}:${
    now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes()
  }`
}

export function zeroPadding(num, digit) {
  let zero = ''
  for (let i = 0; i < digit; i++) {
    zero += '0'
  }
  return (zero + num).slice(-digit)
}

const formatPaddingTime = (time) => (time <= 9 ? `0${time}` : time)

export const formatTime = (days, hours, minutes, seconds) => {
  return `${days > 0 ? `${days}d : ` : ''}${formatPaddingTime(hours)}h : ${formatPaddingTime(
    minutes,
  )}m : ${formatPaddingTime(seconds)}s`
}

export const getInputDecimal = (value: string, decimal: number = 3) =>
  value.indexOf('.') >= 0 ? value.substr(0, value.indexOf('.')) + value.substr(value.indexOf('.'), decimal + 1) : value

export const getCurrentTime = () => getUnixTime(new Date())

export function createHash(address: string, refCode: string, token: string) {
  const secret = process.env.REACT_APP_RECAPTCHA_PASSWORD
  const input = address + refCode + token + secret
  const hash = SHA256(input)
  return hash.toString()
}

export function createArray(size: number, method = (i) => i) {
  var x = []
  for (var i = 0; i < size; ++i) x[i] = method(i)
  return x
}

export function arrayPagination<T>(fullArray: T[], currentPage: number, rowPerPage: number): T[] {
  return fullArray.slice((currentPage - 1) * rowPerPage, (currentPage - 1) * rowPerPage + rowPerPage)
}
