import { BASE_ADD_LIQUIDITY_URL, BASE_LIQUIDITY_POOL_URL } from 'config'

export const HOME = {
  label: 'Home',
  icon: 'HomeIcon',
  href: '/',
}

export const EXCHANGE = {
  label: 'Trade',
  icon: 'TradeIcon',
  href: '/trade',
  // href: BASE_ADD_LIQUIDITY_URL,
  // items: [
  //   {
  //     label: 'Exchange',
  //     href: BASE_ADD_LIQUIDITY_URL,
  //   },
  //   {
  //     label: 'Liquidity',
  //     href: BASE_LIQUIDITY_POOL_URL,
  //   },
  // ],
}

export const MORE_INFO = {
  label: 'Member',
  icon: 'MoreIcon',
  href: '/team-members',
  // items: [
  //   /* {
  //     label: 'Info',
  //     href: '/info/overview',
  //   },
  //   {
  //     label: 'IDO',
  //     href: '/ido',
  //   }, */
  //   {
  //     label: 'Member',
  //     href: '/team-members',
  //   },
  //   /* {
  //     label: 'Audit Report',
  //     href: 'https://github.com/verichains/public-audit-reports/blob/main/Verichains%20Public%20Audit%20Report%20-%20Winery%20Smartcontracts%20-%20v1.1.pdf',
  //     outSide: true,
  //   }, */
  //   /* {
  //     label: 'Github',
  //     href: 'https://github.com/winery-land',
  //     outSide: true,
  //   }, */
  //   {
  //     label: 'Docs',
  //     href: 'https://pools-winery-ai-dex.gitbook.io/pools-winery-ai-dex/welcome/pools-winery-ai-dex-introduction',
  //     outSide: true,
  //   },
  //   /* {
  //     label: 'Blog',
  //     href: 'https://winery-official.medium.com/',
  //     outSide: true,
  //   }, */
  //   /* {
  //     label: 'Privacy Policy',
  //     href: '/WineryPrivacyPolicy.pdf',
  //     outSide: true,
  //   },
  //   {
  //     label: 'Terms Of Service',
  //     href: '/WineryTermsOfServices.pdf',
  //     outSide: true,
  //   }, */
  //   // {
  //   //   label: 'Partnership Application',
  //   //   href: 'https://docs.google.com/forms/d/e/1FAIpQLSdiC4jpKQAYD4iALGrm9ErmDIs1xtsOENu9GsvgdczVwe_uOw/viewform?usp=sf_link',
  //   // },
  // ],
}
