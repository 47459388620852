import { MenuEntry } from '@apeswapfinance/uikit'
import { CHAIN_ID, NETWORK_INFO_LINK } from 'config/constants/chains'
import { HOME, EXCHANGE, MORE_INFO } from '../constants'

const etherConfig: MenuEntry[] = [
  HOME,
 
  EXCHANGE,
  {
    label: 'Earn',
    icon: 'EarnIcon',
    items: [
      {
        label: 'Oak barrel',
        href: '/earn/farms',
      },
      {
        label: 'Champagne',
        href: '/earn/pools/history',
      },
    ],
  },
 
  MORE_INFO,
]

export default etherConfig
