import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { ApeSwapTheme } from '@apeswapfinance/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends ApeSwapTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Montserrat', sans-serif;
  }
  body {
    background-image: url('/backgroundblur.png');
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center;
    min-height:100vh;
    img {
      height: auto;
      max-width: 100%;
    }
  }
  .tooltip-hover{
    font-size: 26px; 
    line-height:1.5; 
    color: ${({ theme }) => theme.colors.text};
  }
  .tooltip-date-hover {
    font-size: 16px; 
    line-height:1.5; 
    color: ${({ theme }) => theme.colors.textLight};
    font-weight:400;
  }
  .grecaptcha-badge { 
    visibility: hidden;
  }

  #chatbase-message-bubbles{
    bottom: 100px;
  }

  #chatbase-bubble-window{
    bottom: 140px !important;
    height: 560px !important;
    width: 430px !important;
  }


  #chatbase-bubble-button{
    /* display: none; */
    right: 0px !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    height: 90px !important;
    width: 80px !important;
    bottom: 30px !important;
    background-color: transparent !important;
    background-image: url('/light.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  #chatIcon{
    visibility:hidden;
  }

  #closeIcon{
    visibility:hidden;
  }

  @media(min-width: 852px){
    #chatbase-bubble-button{
      bottom: 20px !important;
      height: 130px !important;
      right: 20px !important;
      width: 130px !important;
    }
    #chatbase-bubble-window{
      bottom: 170px !important;
      height: calc(100vh - 350px) !important;
    }
  }
  @media(min-width: 1600px){
    #chatbase-bubble-button{
      bottom: 20px !important;
      height: 250px !important;
      right: 20px !important;
      width: 215px !important;
    }
    #chatbase-bubble-window{
      bottom: 250px !important;
      height: calc(100vh - 300px) !important;
    }
  }
}
`

export default GlobalStyle
