import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import './i18n'
import Providers from './Providers'
import * as serviceWorker from './serviceWorker'

// Sentry.init({
//   dsn: 'https://37f906df4f814d82b43c5a5bbec2cfab@o4503924049772544.ingest.sentry.io/4503930007257088',
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// })

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <Router>
        <App />
      </Router>
    </Providers>
  </React.StrictMode>,
  document.getElementById('root'),
)

serviceWorker.register()
