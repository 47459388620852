import { Ifo } from './types'

const ifos: Ifo[] = [
  {
    id: 'lrps',
    address: '0x024df50f3824b94b019b6f8e2d933cec4463e420',
    isActive: true,
    isLinear: false,
    isPrivate: false,
    projectName: 'LEGEND OF RPS',
    name: 'Legend of RPS (LRPS)',
    subTitle: 'LEGEND OF RPS',
    description:
      'Legend of RPS is an extremely simple P2E game for the ultimate fun and satisfaction to players, with each easy-to-win match lasts only for a few minutes. You can play freely and will get real-time rewards based on your match results.',
    launchDate: 1659938400000,
    endDate: 1659952800000, 
    launchTime: '00:00 UTC',
    listingDate: 1660096800000,
    claimingDate: 1660096800000,
    saleAmount: '569,283.3 LRPS',
    raiseAmount: '56,928.33 BUSD',
    swapRate: 0.1,
    projectSiteUrl: 'https://rpsgame.world',
    projectSiteTwitter: 'https://twitter.com/legend_rps',
    projectSiteTelegram: 'https://t.me/legendofRPS',
    projectSiteDiscord: 'https://discord.gg/FGBgH6fs8M',
    projectWhitePaper: 'https://rpsgame.world/static/media/LRPS_WhitePaper_ENG_1807_2022.pdf',
    currency: 'BUSD',
    currencyAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    offeringCurrency: 'LRPS',
    offeringCurrencyAddress: '0x57f251706a6e4f5bb0A39eBaEb2335e3aF606057',
    tokenDecimals: 18,
    releaseBlockNumber: 1659693600,
    vesting: true,
    totalSupply: 1000000000,
    distribution: '5% at TGE + 95% monthly vesting over 6 months',
    phase: 7,
  },
  {
    id: 'nft-marble-private',
    address: '0x9cc6007492d910dcC4Cd667bA6106A65D03C4a10', // Leave empty for "Coming Soon!"
    isActive: true,
    isLinear: false,
    isPrivate: true,
    hasPublic: true,
    projectName: 'NFT MARBLE GAME',
    name: 'NFT MARBLE TOKEN DOZEN',
    subTitle: `An online board game - Easy to play, easy to earn.`,
    description: `NFT MARBLE is an online board game on blockchain technology with simple play, combine of
    NFT and online board game, various tactics and different modes. You can gather friends, form
    a team, play board games with others and earn money with the skills. Strategic battles using
    various skills of the characters and dice
    `,
    launchDate: 1655956800000,
    endDate: 1655962200000,
    launchTime: '00:00 UTC',
    listingDate: 1656050400000,
    claimingDate: 1656051000000,
    saleAmount: '287,500 DOZEN',
    raiseAmount: '23,000 BUSD',
    swapRate: 0.08,
    projectSiteUrl: 'https://nftmarble.games/',
    projectSiteTwitter: 'https://twitter.com/nftmarblegame',
    projectSiteTelegram: 'https://t.me/nftmarblegame',
    projectSiteDiscord: 'https://discord.com/invite/usngnb2DJY',
    projectWhitePaper: 'https://nftmarble.games/static/media/whitepaper.pdf',
    currency: 'BUSD',
    currencyAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    offeringCurrency: 'DOZEN',
    offeringCurrencyAddress: '0xc236a71f9633a0be7d97ad68f8c16de6ec46a252',
    tokenDecimals: 18,
    releaseBlockNumber: 1655453555, // 17/06/2022
    vesting: true,
    totalSupply: 1000000000,
    distribution: '10% at TGE + 90% monthly vesting over 3 months',
    phase: 4,

  },
  {
    id: 'nft-marble',
    address: '0xFA1637E8C3Db2f0D3ad57ca8B76c2B6ff942b2Ec', // Leave empty for "Coming Soon!"
    isActive: true,
    isLinear: false,
    isPrivate: false,
    hasPublic: true,
    projectName: 'NFT MARBLE GAME',
    name: 'NFT MARBLE TOKEN DOZEN',
    subTitle: `An online board game - Easy to play, easy to earn.`,
    description: `NFT MARBLE is an online board game on blockchain technology with simple play, combine of
    NFT and online board game, various tactics and different modes. You can gather friends, form
    a team, play board games with others and earn money with the skills. Strategic battles using
    various skills of the characters and dice
    `,
    launchDate: 1655962200000,
    endDate: 1655967600000,
    launchTime: '00:00 UTC',
    listingDate: 1656050400000,
    claimingDate: 1656051000000,
    saleAmount: '25,000 DOZEN',
    raiseAmount: '2,000 BUSD',
    swapRate: 0.08,
    projectSiteUrl: 'https://nftmarble.games/',
    projectSiteTwitter: 'https://twitter.com/nftmarblegame',
    projectSiteTelegram: 'https://t.me/nftmarblegame',
    projectSiteDiscord: 'https://discord.com/invite/usngnb2DJY',
    projectWhitePaper: 'https://nftmarble.games/static/media/whitepaper.pdf',
    currency: 'BUSD',
    currencyAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    offeringCurrency: 'DOZEN',
    offeringCurrencyAddress: '0xc236a71f9633a0be7d97ad68f8c16de6ec46a252',
    tokenDecimals: 18,
    releaseBlockNumber: 1655942400, // 23/6/2022 7h GMT + 7
    vesting: true,
    totalSupply: 1000000000,
    distribution: '10% at TGE + 90% monthly vesting over 3 months',
    phase: 4,

  },
  {
    id: 'bingo-family',
    address: '0xeDAEA42Cb4060931a5961023eec7dbA1c34e7f35', // Leave empty for "Coming Soon!"
    projectName: 'BINGO FAMILY',
    isActive: true,
    isLinear: false,
    isPrivate: true,
    hasPublic: false,
    name: 'BINGO FAMILY TOKEN',
    subTitle: `Bingo Family game where players can earn Points to get tokens and cash prizes!`,
    description: `
    Bingo Family Game is a fun game played by people 
    all over the world. It is an NFT online board game based on blockchain. A fair and enjoyable game in which the players' time and effort are fully rewarded.
    `,
    launchDate: 1655784000000, // 21/06/2022
    endDate: 1655794800000,
    launchTime: '00:00 UTC',
    listingDate: 1655877600000,
    claimingDate: 1655878200000,
    saleAmount: '156,250 BGOF',
    raiseAmount: '25,000 BUSD',
    swapRate: 0.16,
    projectSiteUrl: 'https://bingo.family/',
    projectSiteTwitter: 'https://twitter.com/NFTBingo1',
    projectSiteTelegram: 'https://t.me/Bingo_Family',
    projectSiteDiscord: 'https://discord.com/invite/D3sMxfbxZp',
    projectWhitePaper: 'https://bingo.family/static/document/whitepaper_en.pdf',
    currency: 'BUSD',
    currencyAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    offeringCurrency: 'BGOF',
    offeringCurrencyAddress: '0x5f949De3131f00B296Fc4c99058D40960B90FAbC',
    tokenDecimals: 18,
    releaseBlockNumber: 1655453555, // 17/06/2022
    vesting: true,
    totalSupply: 1000000000,
    distribution: '10% at TGE + 90% monthly vesting over 3 months',
    phase: 4,

  },
]

export const pastIfos: Ifo[] = ifos.filter((ifo) => !ifo.isActive)

export default ifos
