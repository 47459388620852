import { Card, CloseIcon, GradientButton, LinkExternal, Text, useModal } from '@apeswapfinance/uikit'
import React from 'react'
import { Youtube } from 'react-feather'
import styled from 'styled-components'
import { Grid } from 'views/NftLaunchPad/styles'
import RightArrowIcon from './RightArrowIcon'

const StyledCard = styled(Card)`
  padding: 2rem;

  position: relative;
  z-index: 100;
  max-width: 90%;
  width: 500px;
  min-height: 350px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
`

const StyledCloseIcon = styled(CloseIcon)<{ width?: string }>`
  position: absolute;
  right: 2rem;
  top: 2rem;
  cursor: pointer;
  transition: 0.2s ease-out;
  ${({ width }) => width && `width:${width};`}
  :hover {
    opacity: 0.8;
  }
`

const StyledImg = styled.img<{ width?: string }>`
  width: ${({ width }) => width ?? '100%'};
  height: 80%;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.primary};
`

const FlexRow = styled.div`
  display: flex;
  margin: -8px;
  justify-content: center;
  padding-bottom: 1rem;
  > div {
    padding: 8px;
    width: 50%;
    > a {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
`

const SkeletonVideo = styled.div`
  position: relative;
  display: block;
`

const YoutubePlay = styled(GradientButton)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 30px;

  opacity: 0.4;
`

const Wrapper = styled.div`
  z-index: 1000;
  position: relative;
  max-width: 90%;
  height: 500px;
  width: 800px;
`

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
`

const CloseIframeIcon = styled(StyledCloseIcon)`
  top: -2rem;
  right: 0;
`

const IframeModal: React.FC<{ onDismiss?: () => void; src: string }> = ({ onDismiss, src }) => {
  return (
    <Wrapper>
      <CloseIframeIcon width="30px" onClick={onDismiss} />
      <StyledIframe
        src={src}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Wrapper>
  )
}

const GuideConnectMobileModal: React.FC<{ onDismiss?: () => void }> = ({ onDismiss }) => {
  const [showVideo] = useModal(<IframeModal src="https://www.youtube.com/embed/r32quy4P6ew?rel=0&autoplay=1" />)

  return (
    <StyledCard>
      <StyledCloseIcon onClick={onDismiss} />
      <Text pt={4} mb={2} fontSize="18px" fontWeight={500} textAlign="center">
        If you are new here, let's watch it!
      </Text>

      <Text mb={3} fontSize="20px" bold textAlign="center" color="#DA113E">
        How to connect your wallet on a mobile phone
      </Text>

      <FlexRow>
        {/* <div>
          <SkeletonVideo as="a">
            <StyledImg alt="mobile guide" src="/images/guide/MetamaskMobileGuide.png" />
            <YoutubePlay
              onClick={() => {
                // onDismiss()
                showVideo()
              }}
            >
              <RightArrowIcon width="20px" />
            </YoutubePlay>
          </SkeletonVideo>

          <Text textAlign="center">
            <LinkExternal style={{ margin: '0 auto' }} href="https://www.youtube.com/watch?v=r32quy4P6ew">
              Youtube
            </LinkExternal>
          </Text>
        </div> */}
        <div>
          <SkeletonVideo as="a" href="/How-to-connect-metamask.pdf" rel="noopener noreferrer" target="_blank">
            <StyledImg alt="mobile guide" src="/images/guide/MetamaskMobileGuide.png" />
          </SkeletonVideo>

          <Text textAlign="center">
            <LinkExternal style={{ margin: '0 auto' }} href="/How-to-connect-metamask.pdf">
              PDF
            </LinkExternal>
          </Text>
        </div>
      </FlexRow>
    </StyledCard>
  )
}

export default GuideConnectMobileModal
