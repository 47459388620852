import { useWeb3React } from '@web3-react/core'
import { CHAIN_PARAMS } from 'config/constants/chains'
import { truncate } from 'lodash'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchChainIdFromUrl, fetchUserNetwork } from 'state/network'

const useSwitchNetwork = () => {
  const { chainId, account, library } = useWeb3React()
  const [switchLoading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const switchNetwork = useCallback(
    async (userChainId: number) => {
      setLoading(true)
      if (account && userChainId !== chainId) {
        try {
          const switchChainRes = await library?.send('wallet_switchEthereumChain', [
            { chainId: CHAIN_PARAMS[userChainId].chainId },
            account,
          ])
          if (switchChainRes) {
            dispatch(fetchChainIdFromUrl(false))
          }
        } catch (error) {
          const addChainRes = await library?.send('wallet_addEthereumChain', [CHAIN_PARAMS[userChainId], account])
          if (addChainRes) {
            dispatch(fetchChainIdFromUrl(false))
          }
          console.warn(error)
        }
      } else {
        dispatch(fetchUserNetwork(chainId, account, userChainId))
      }
      setLoading(false)
    },
    [chainId, account, library, dispatch],
  )
  return { switchNetwork, switchLoading }
}

export default useSwitchNetwork
